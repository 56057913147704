define([
    'santa-components',
    'socialCommon',
    'skins',
    'facebookShare/skins/skins.json',
    'componentsCore'
], function (santaComponents, socialCommon, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    /**
     * @class components.FacebookShare
     * @extends {core.skinBasedComp}
     */
    const facebookShare = {
        displayName: 'FacebookShare',
        mixins: [componentsCore.mixins.skinBasedComp, socialCommon.socialCompMixin],
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            openPopup: santaComponents.santaTypesDefinitions.popup.open.isRequired
        },
        getUrlToBeShared() {
            const isSiteUrl = this.props.compData.urlChoice.toLowerCase() === 'site';
            const urlToBeShared = this.getSocialUrl(isSiteUrl);
            return encodeURIComponent(urlToBeShared);
        },
        getFacebookSharer() {
            return 'http://www.facebook.com/sharer.php?u=';
        },
        openSharePopup() {
            const name = 'wix_share_to_facebook';
            const params = 'width = 635, height = 346, scrollbars = no, status = no, toolbar = no, menubar = no, location = no';
            this.props.openPopup(this.getFacebookSharer() + this.getUrlToBeShared(), name, params);
        },
        getSkinProperties() {
            return {
                '': {
                    style: {
                        height: '',
                        width: ''
                    }
                },
                facebookShareButton: {
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'a'),
                    onClick: this.openSharePopup,
                    title: this.props.compData.label,
                    'aria-label': this.props.compData.label,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                label: {
                    children: [this.props.compData.label]
                },
                shareButton: {},
                icon: {}
            };
        }
    };

    skinsPackage.skinsMap.addBatch(skinsJson);

    return facebookShare;
});
