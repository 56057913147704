(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['skins.viewer.facebookshare.FacebookShareSkin'] = {
  "react": [
    [
      "div",
      "facebookShareButton",
      [],
      {
        "tabIndex": 0
      },
      [
        "div",
        "shareButton",
        [],
        {
          "tabIndex": "-1"
        },
        [
          "span",
          "icon",
          [],
          {}
        ],
        [
          "span",
          "label",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%facebookShareButton": "border:1px solid #2d4565;border-radius:3px;cursor:pointer;min-width:24px;white-space:nowrap;display:inline-block;",
    "%shareButton": "height:20px;border-radius:2px;border-width:1px;border-style:solid;border-color:#9aabc6 #6f83ad #6176a3;background:url([tdr]bg_fbshare.png) 0 0 repeat-x;",
    "%shareButton:hover": "box-shadow:inset 0 8px 13px -4px rgba(255, 255, 255, 0.4);",
    "%shareButton:active": "box-shadow:inset 5px 5px 10px -4px rgba(0, 0, 0, 0.4);",
    "%icon": "width:21px;height:21px;background:url([tdr]facebooklogo.png) 5px 3px no-repeat;border-right:1px solid #425e85;position:absolute;",
    "%label": "line-height:21px;margin-left:22px;padding:3px 6px;color:#fff;font-size:12px;text-shadow:1px 1px 1px #304871;border-left:1px solid #6176a3;"
  }
}

        return skins;
    }));